<template>
  <div class="navbar">
    <!-- 汉堡按钮 -->
    <hamburger class="hamburger-container"></hamburger>

    <!-- 面包屑 -->
    <Breadcrumb class="breadcrumb-container"></Breadcrumb>

    <div class="right-menu">
      <!-- 国际化 -->
      <!-- <Lang-select class="right-menu-item hover-effect"></Lang-select> -->

      <!-- 头像 -->
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <img src="~@/assets/img/user.jpg" alt=""  />
<!--          <img src="~@/assets/img/0.png" alt=""  />-->
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-menu class="user-dropdown">
              <router-link to="/">
                <el-dropdown-item> 首页 </el-dropdown-item>
              </router-link>
              <el-dropdown-item @click="changePassword">
                修改密码
              </el-dropdown-item>
              <el-dropdown-item divided @click="store.logout">
                退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <!-- 修改密码 -->
    <el-dialog v-model="dialogVisible" title="修改密码" width="30%">
      <el-form ref="ruleFormRef" :model="ruleForm" status-icon :rules="rules">
        <el-form-item label="修改密码" prop="password">
          <el-input v-model="ruleForm.password" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm(ruleFormRef)">取消</el-button>
          <el-button type="primary" @click="submitForm(ruleFormRef)"
            >确认</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { userStore } from '@/store'
import hamburger from '@/components/hamburger'
import Breadcrumb from '@/components/Breadcrumb'
// import LangSelect from '@/components/LangSelect'
import { revisePasswordAPI } from '@/api/sys'
import { ElMessage } from 'element-plus'
const store = userStore()
const ruleForm = ref({
  id: store.userid,
  password: ''
})

const rules = ref({
  password: [
    { required: true, message: '请输入中文名称', trigger: 'blur' },
    {
      pattern: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^\da-zA-Z\s]).{6,16}$/,
      message: '至少包含字母、数字、特殊字符,6-16位',
      trigger: 'blur'
    }
  ]
})
const ruleFormRef = ref(null)

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      await revisePasswordAPI(ruleForm.value)
      ElMessage.success('密码修改成功')
      store.logout()
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  dialogVisible.value = false
}

const dialogVisible = ref(false)

const changePassword = () => {
  dialogVisible.value = true
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .right-menu {
    display: flex;
    align-items: center;
    float: right;
    padding-right: 16px;

    ::v-deep .avatar-container {
      cursor: pointer;
      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        .el-avatar {
          background-color: none;
          margin-right: 12px;
        }
      }
    }

    // 国际化样式
    ::v-deep .right-menu-item {
      display: inline-block;
      padding: 0 18px 0 0;
      font-size: 24px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
      }
    }
  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    // hover 动画
    transition: background 0.5s;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  // 面包屑
  .breadcrumb-container {
    float: left;
  }
}
</style>
