// 门店管理
import layout from '@/layout/index.vue'
export default {
  path: '/',
  component: layout,
  redirect: '/rotation',
  name: 'staffStore',
  children: [
    {
      path: '/store',
      component: () => import('@/views/store/index.vue'),
      meta: {
        title: '门店管理',
        icon: 'mendian'
      }
    }
  ]
}
