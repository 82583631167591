// 登记记录表
import layout from '@/layout/index.vue'
export default {
  path: '/',
  component: layout,
  redirect: '/record',
  name: 'registerHx',
  children: [
    {
      path: '/record',
      component: () => import('@/views/record/index.vue'),
      meta: {
        title: '登记记录',
        icon: '登记'
      }
    }
  ]
}
