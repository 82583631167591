// 登记记录表
import layout from '@/layout/index.vue'
export default {
  path: '/',
  component: layout,
  redirect: '/reservation',
  name: 'reservationRf',
  children: [
    {
      path: '/reservation',
      component: () => import('@/views/reservation/index.vue'),
      meta: {
        title: '预约染发记录',
        icon: '预约'
      }
    }
  ]
}
