// organizationStaff
// 员工管理
import layout from '@/layout/index.vue'
export default {
  path: '/account',
  component: layout,
  name: 'accountCustomer',
  redirect: 'account/customer',
  meta: {
    title: '账号管理',
    icon: 'zuzhi'
  },
  children: [
    {
      path: '/account/customer',
      component: () => import('@/views/account/customer/index.vue'),
      meta: {
        title: '顾客管理',
        icon: 'zuzhi'
      }
    }
  ]
}
