import axios from 'axios'
import { userStore } from '../store/index'
import { isCheckTimeout } from '@/utils/auth'
import { ElMessage } from 'element-plus'

// TODO 环境变量
const service = axios.create({
  //  process.env 获取环境变量中不同的变量值
  baseURL: process.env.VUE_APP_BASE_API
  // timeout: 10000
})

// TODO 请求拦截器
service.interceptors.request.use(
  (config) => {
    const store = userStore()
    // Token 存在则注入 token
    if (store.token) {
      if (isCheckTimeout()) {
        // 执行退出操作
        store.logout()
        // 提示用户重新登录
        // ElMessage.error('token 失效，请重新登录') // 提示错误消息
        // return Promise.reject(new Error('token 失效,请重新登录'))
        return Promise.reject(new Error('登录超时,请重新登录'))
      }

      // 如果token存在 注入token
      config.headers.Authorization = `Bearer ${store.token}`
    }
    // 必须返回 config
    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

// TODO 响应拦截器
service.interceptors.response.use(
  // TODO 成功`
  (response) => {
    const { code, msg, data } = response.data
    //   要根据 code 状态决定下面的操作
    if (code === 0) {
      return data
    } else {
      // 业务错误
      // 如果 token 超时则不返回业务错误
      if (code !== 10001) {
        ElMessage.error(msg) // 提示错误消息
      }

      return Promise.reject(new Error(msg))
    }
  },
  // TODO 失败
  (error) => {
    const store = userStore()
    // ! 单点登录
    // ! 响应拦截器进行处理 ，单点登录问题
    // 处理 token 超时问题
    if (
      error.response &&
      error.response.data &&
      error.response.data.code === 10001
    ) {
      // token超时
      store.logout()
    }
    ElMessage.error(error.message) // 提示错误信息
    return Promise.reject(error)
  }
)

export default service
