// organizationStaff
// 员工管理
import layout from '@/layout/index.vue'
export default {
  path: '/account',
  component: layout,
  name: 'accountStaff',
  redirect: 'account/staff',
  meta: {
    title: '账号管理',
    icon: 'zuzhi'
  },
  children: [
    {
      path: '/account/staff',
      component: () => import('@/views/account/staff/index.vue'),
      meta: {
        title: '员工管理',
        icon: 'zuzhi'
      }
    }
  ]
}
