export const TOKEN = 'token'

// TODO token 获取时间戳
export const TIME_STAMP = 'timeStamp'
// TODO 超时时长
export const TOKEN_TIMEOUT_VALUE = 2 * 3600 * 1000
// export const TOKEN_TIMEOUT_VALUE = 3000

// TODO 国际化
export const LANG = 'language'
// 退费
export const REFUND = 'refund'
// 退费
export const REFUNDA = 'refundA'

// 权限路由
export const PERMISSION = 'permission'

// 用户 ID
export const USER_ID = 'userid'
