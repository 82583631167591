// organizationStaff
// 员工管理
import layout from '@/layout/index.vue'
export default {
  path: '/product',
  component: layout,
  name: 'productMain',
  redirect: 'product/main',
  meta: {
    title: '产品管理',
    icon: 'zuzhi'
  },
  children: [
    {
      path: '/product/main',
      component: () => import('@/views/product/main/index.vue'),
      meta: {
        title: '主品管理',
        icon: 'zuzhi'
      }
    }
  ]
}
