// aboutUs
// 关于我们
import layout from '@/layout/index.vue'
export default {
  path: '/',
  component: layout,
  redirect: '/rotation',
  name: 'aboutUs',
  children: [
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: () => import('@/views/about-us/index.vue'),
      meta: {
        title: '公司简介',
        icon: 'guanyu'
      }
    }
  ]
}
